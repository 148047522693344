<template>
	<div v-if="authed">
		<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
			:loading="loading" loading-text="加载中..." show-select>
			<template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px">
					<v-btn-toggle mandatory dense v-model="filter" @change="reloadData">
						<v-btn>待交接</v-btn>
						<v-btn>已交接</v-btn>
						<v-btn>全部</v-btn>
					</v-btn-toggle>
					<v-select :items="pdateSelects" v-model="pdateSelect" dense hide-details style="max-width:140px" @change="reloadData"/>
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="日期起" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="日期止" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-select label="机构" persistent-placeholder placeholder="全部" clearable v-model="pagency" :items="pathology_agencies" dense hide-details
						style="flex: 0 1 auto;max-width:150px;" @change="reloadData"/>
					<v-btn color="primary" @click.stop="exportXLS">导出</v-btn>
				</div>
				<v-divider/>
			</template>
			<template v-slot:item.progress.operative="{ item }">
				{{formatTime(item.progress.operative)}}
			</template>
			<template v-slot:item.progress.pathologyTransfered="props">
				<v-edit-dialog v-if="!!props.item.progress.pathologyTransfered" :return-value.sync="props.item.progress.pathologyTransfered" large
					save-text="保存" cancel-text="取消" @save="saveDatetime('progress.pathologyTransfered', props)">
					{{formatTime(props.item.progress.pathologyTransfered)}}
					<template v-slot:input>
						<v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field :value="formatDate(props.item.progress.pathologyTransfered)" readonly v-bind="attrs"
									v-on="on" hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							</template>
							<v-date-picker
								:value="formatDate(props.item.progress.pathologyTransfered)"
								@input="input3(props, $event)"
								no-title scrollable>
							</v-date-picker>
						</v-menu>
					</template>
				</v-edit-dialog>
			</template>
			<template v-slot:item.progress.pathologyReturn="{ item }">
				{{formatTime(item.progress.pathologyReturn)}}
			</template>
			<template v-slot:item.progress.pathologyAdditionReturn="{ item }">
				{{formatTime(item.progress.pathologyAdditionReturn)}}
			</template>
			<template v-slot:item.pathology="{ item }">
				{{getPathology(item)}}
			</template>
			<template v-slot:item.age="{ item }">
				{{getAge(item)}}
			</template>
			<template v-slot:item.opts="{ item }">
				{{getOptsStr(item)}}
			</template>
			<template v-slot:item.pathologyAgency="props">
				<v-edit-dialog large :return-value.sync="props.item.pathologyAgency"
					save-text="保存" cancel-text="取消" @save="saveItem('pathologyAgency', props)">
					{{props.item.pathologyAgency}}
					<template v-slot:input>
						<v-select :items="pathology_agencies" v-model="props.item.pathologyAgency" clearable/>
					</template>
				</v-edit-dialog>
			</template>
			<template v-slot:footer.prepend>
				<v-btn color="primary" @click.stop="transfer" style="width:100px" :disabled="loading||(selected.length===0)">确认交接</v-btn>
			</template>
		</v-data-table>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate, calculateAge, pathology_agencies} from '../utils'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:90},
                    {text:'姓名', value:'pii.username', width:120},
//                    {text:'性别', value:'pii.gender', width:80},
//                    {text:'年龄', value:'age', width:80},
                    {text:'套餐内容', value:'opts', width:120, sortable: false},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'病理交接时间', value:'progress.pathologyTransfered', width:180},
                    {text:'报告返回时间', value:'progress.pathologyReturn', width:180},
                    {text:'追加返回时间', value:'progress.pathologyAdditionReturn', width:180},
                    {text:'病理', value:'pathology', width:180},
                    {text:'机构', value:'pathologyAgency', width:120},
                    {text:'追加-胃', value:'pathologyAdditionW', width:150},
                    {text:'追加-肠', value:'pathologyAdditionC', width:150},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {sortBy:['progress.operative'], sortDesc:[false]},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				menu3: false,
				pdateSelects: [
					{text:'手术日期', value:'progress.operative'},
					{text:'交接日期', value:'progress.pathologyTransfered'},
					{text:'报告返回日期', value:'progress.pathologyReturn'},
				],
				pdateSelect: 'progress.operative',
				pathology_agencies,
				pagency: null,
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
			},
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.reloadData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.reloadData();
			},
		},
		created() {
			this.formatTime = formatTime;
			this.formatDate = formatDate;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
		},
        mounted() {
			this.authed = this.$hasPrivilege(['病理传递']);
			if (!this.authed) return;
		},
        methods: {
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.PRO;
			},
			getPathology(item) {
				if (!item.endoscopyResult) return '-';
				const o = item.endoscopyResult;
				return `胃${o.pathology_p_w||0}瓶${o.pathology_k_w||0}块，肠${o.pathology_p_c||0}瓶${o.pathology_k_c||0}块`;
			},
			async reloadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					res.data.forEach(x => x.isSelectable = !x.progress.pathologyTransfered);						
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getFilter() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{'progress.pathologyTransfered':_.exists(false)},
					{'progress.pathologyTransfered':_.exists(true)},
					{}
				];
				let filter = [{'endoscopyResult.pathology':'有'}];
				if (this.pagency) {
					filter.push({pathologyAgency:this.pagency});
				}
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				if (t1 && t2) {
					filter.push({[this.pdateSelect]:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({[this.pdateSelect]:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({[this.pdateSelect]:_.lt(t2)});
				} else {
					filter.push({"progress.operative":_.exists(true)});
				}
				const f = filter.concat(filters[this.filter]);
				return _.and(f);
			},
			async transfer() {
				if (this.selected.length === 0) return;
				const ids = this.selected.map(x => x._id);
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname: 'transferPathology',
							data: {
								ids,
							}
						}
					});
					await this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			async saveDatetime(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editDate',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			input3(props, e) {
				props.item.progress.pathologyTransfered = new Date(e + 'T00:00:00+08:00');
				this.menu3 = false;
			},
			async exportXLS() {
				const header = [[
					'交接日期',
					'送检诊所',
					'手术日期',
					'客户姓名',
					'病理申请单号',
					'部位',
					'瓶数',
					'块数',
					'报告签收日期',
					'追加项目',
					'追加项目报告签收日期',
					'机构',
				]];
				const { sortBy, sortDesc } = this.options;
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				if (total === 0) {
					this.$dialog.message.warning('没有需要导出的送检项目');
					return;
				}
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const es = [];
				res.data.forEach(x => {
					if (x.endoscopyResult.pathology_p_w) {
						let y = Object.assign({}, x);
						y.part = 'W';
						y.p = x.endoscopyResult.pathology_p_w;
						y.k = x.endoscopyResult.pathology_k_w;
						y.a = x.pathologyAdditionW;
						es.push(y);
					}
					if (x.endoscopyResult.pathology_p_c) {
						let y = Object.assign({}, x);
						y.part = 'C';
						y.p = x.endoscopyResult.pathology_p_c;
						y.k = x.endoscopyResult.pathology_k_c;
						y.a = x.pathologyAdditionC;
						es.push(y);
					}
				});
				if (es.length === 0) {
					this.$dialog.message.warning('没有需要导出的送检项目');
					return;
				}
				const data = es.map(x => [
					formatDate(x.progress.pathologyTransfered),
					'北京华三诊所',
					formatDate(x.progress.operative),
					x.pii.username,
					x.pid,
					x.part,
					x.p,
					x.k || 0,
					formatDate(x.progress.pathologyReturn),
					x.a || '',
					formatDate(x.progress.pathologyAdditionReturn),
					x.pathologyAgency,
				]);
				const t = '华三病理交接';
				const filename = t + ".xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data));
				const bs = {
					style: "thin",
					color: "000000"
				};
				for(let i in ws) {
					if (typeof(ws[i]) != "object") continue;
					ws[i].s = {
						border: {
							top: bs, left: bs, right: bs, bottom:bs
						}
					}
				}
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
		},
    }
</script>
