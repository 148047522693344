var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{ itemsPerPageOptions: [10, 30, 50] },"fixed-header":"","loading":_vm.loading,"loading-text":"加载中...","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap pt-3 pb-1 pl-1",staticStyle:{"gap":"16px"}},[_c('v-btn-toggle',{attrs:{"mandatory":"","dense":""},on:{"change":_vm.reloadData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},[_c('v-btn',[_vm._v("待交接")]),_c('v-btn',[_vm._v("已交接")]),_c('v-btn',[_vm._v("全部")])],1),_c('v-select',{staticStyle:{"max-width":"140px"},attrs:{"items":_vm.pdateSelects,"dense":"","hide-details":""},on:{"change":_vm.reloadData},model:{value:(_vm.pdateSelect),callback:function ($$v) {_vm.pdateSelect=$$v},expression:"pdateSelect"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"label":"日期起","readonly":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}},'v-text-field',attrs,false),on))]}}],null,false,3301009859),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.pdate1),callback:function ($$v) {_vm.pdate1=$$v},expression:"pdate1"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"label":"日期止","readonly":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}},'v-text-field',attrs,false),on))]}}],null,false,4036211445),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.pdate2),callback:function ($$v) {_vm.pdate2=$$v},expression:"pdate2"}})],1),_c('v-select',{staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"label":"机构","persistent-placeholder":"","placeholder":"全部","clearable":"","items":_vm.pathology_agencies,"dense":"","hide-details":""},on:{"change":_vm.reloadData},model:{value:(_vm.pagency),callback:function ($$v) {_vm.pagency=$$v},expression:"pagency"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.exportXLS($event)}}},[_vm._v("导出")])],1),_c('v-divider')]},proxy:true},{key:"item.progress.operative",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.progress.operative))+" ")]}},{key:"item.progress.pathologyTransfered",fn:function(props){return [(!!props.item.progress.pathologyTransfered)?_c('v-edit-dialog',{attrs:{"return-value":props.item.progress.pathologyTransfered,"large":"","save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(props.item.progress, "pathologyTransfered", $event)},"update:return-value":function($event){return _vm.$set(props.item.progress, "pathologyTransfered", $event)},"save":function($event){return _vm.saveDatetime('progress.pathologyTransfered', props)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","max-width":"150px"},attrs:{"value":_vm.formatDate(props.item.progress.pathologyTransfered),"readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"value":_vm.formatDate(props.item.progress.pathologyTransfered),"no-title":"","scrollable":""},on:{"input":function($event){return _vm.input3(props, $event)}}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatTime(props.item.progress.pathologyTransfered))+" ")]):_vm._e()]}},{key:"item.progress.pathologyReturn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.progress.pathologyReturn))+" ")]}},{key:"item.progress.pathologyAdditionReturn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.progress.pathologyAdditionReturn))+" ")]}},{key:"item.pathology",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPathology(item))+" ")]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAge(item))+" ")]}},{key:"item.opts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOptsStr(item))+" ")]}},{key:"item.pathologyAgency",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","return-value":props.item.pathologyAgency,"save-text":"保存","cancel-text":"取消"},on:{"update:returnValue":function($event){return _vm.$set(props.item, "pathologyAgency", $event)},"update:return-value":function($event){return _vm.$set(props.item, "pathologyAgency", $event)},"save":function($event){return _vm.saveItem('pathologyAgency', props)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.pathology_agencies,"clearable":""},model:{value:(props.item.pathologyAgency),callback:function ($$v) {_vm.$set(props.item, "pathologyAgency", $$v)},expression:"props.item.pathologyAgency"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.pathologyAgency)+" ")])]}},{key:"footer.prepend",fn:function(){return [_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","disabled":_vm.loading||(_vm.selected.length===0)},on:{"click":function($event){$event.stopPropagation();return _vm.transfer($event)}}},[_vm._v("确认交接")])]},proxy:true}],null,false,1314045464),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }